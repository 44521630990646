import { FC, useRef, useState, useEffect } from 'react';

import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import user from 'src/assets/img/ico-user.png';
import logoSSMA from 'src/assets/img/logo-arcos-ssma.png';
import logo from 'src/assets/img/logo-arcos.png';
import visibility from 'src/assets/img/visibility.png';
import BockInputArcos from 'src/components/BockInputArcos';
import ButtonArcosLogin from 'src/components/ButtonArcosLogin';
import ContactGreenPlateArcos from 'src/components/ContactGreenPlateArcos';
import ContentTextPage from 'src/components/ContentTextPage';
import InputArcosLogin from 'src/components/InputArcosLogin';
import Title from 'src/components/Title';
import { defaultOptions, formatErrors } from 'src/libs/yup';
import api from 'src/services/api';
import keys from 'src/services/keys';
import terceiro from 'src/services/terceiro';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import getApiErrors from 'src/utils/getApiErrors';
import 'react-toastify/dist/ReactToastify.css';
import loginSchema from 'src/validators/login.schema';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import view from 'src/assets/img/view.png';

import {
  Container,
  Logo,
  FormLogin,
  LeftBox,
  InputArcos,
  RightBox,
  TextEntre,
  TextFooter,
  MenuItemLink,
  ReCaptcha,
  ImageUser,
  FooterArcos,
  SSMA,
} from './styles';

interface FormData {
  email: string;
  password: string;
}

const LoginArcosDourados: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [recaptchavalue, setRecaptchavalue] = useState<string>('');
  const [recaptcha_privateke, setRecaptcha_privateke] = useState<any>(
    '5948086e-e295-43a2-8b3d-189b7723db99'
  );
  const [show_input, setShowInput] = useState<boolean>(false);
  const [showPassConfirm, setShowPassConfirm] = useState<boolean>(true);
  const [showPass, setShowPass] = useState<boolean>(true);
  const [show_input_confirm, setShowInputConfirm] = useState<boolean>(false);
  const funcPassViewConfirm = (passview: boolean) => {
    setShowPassConfirm(!showPassConfirm);
    setShowInputConfirm(!show_input_confirm);
  };

  useEffect(() => {
    const getLogin = async () => {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));

      const erro = localStorage.getItem('erro');
      const sucesso = localStorage.getItem('sucesso');

      if (sucesso && sucesso == '1') {
        toast.success(' Senha editada com sucesso! ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (erro && erro == '1') {
        toast.error(
          ' Sua sessão foi finalizada após 20 minutos de inatividade! ',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else if (erro && erro == '2') {
        // Vamos atualizar a tela, caso já esteja logado no dispositivo atual, já entrará no sistema!
        toast.error(
          ' Não é possível efetuar o login pois a conta está em uso em outro dispositivo! ',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              window.location.href = '/dashboard';
            },
          }
        );
      } else if (erro && erro == '3') {
        toast.error(
          'Login ou senha inválidos! Se o problema persistir, redefina sua senha!',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else if (erro && erro == '4') {
        toast.error(' O tempo limite para logar no sistema foi atingido! ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      localStorage.removeItem('erro');
      localStorage.removeItem('sucesso');
    };
    getLogin();
  }, []);

  const funcErrorEmail = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  const funcErrorPassword = (stateError: boolean) => {
    setErrorPassword(stateError);
  };

  const changeRecaptha = (value) => {
    setRecaptchavalue(value);
  };

  const signInPass = async () => {
    // history.push("/link-enviado");

    if (email == '') {
      toast.error('E-mail não pode ser vazio!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      funcErrorEmail(true);
    } else {
      if (password == '') {
        toast.error('Password não pode ser vazio!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        funcErrorPassword(true);
      } else {
        if (!IsEmail(email)) {
          toast.error('E-mail digitado não é valido!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          if (recaptchavalue == '') {
            toast.error('Validação do hCaptcha é obrigatória!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            let res: any = {};
            res = await authRequest(email, password, recaptchavalue);
          }
        }
      }
    }
  };

  const IsEmail = (email: string) => {
    return true;
  };

  const changeEmail = (email: string) => {
    setEmail(email);
    funcErrorEmail(false);
    funcErrorPassword(false);
  };

  const changePassword = (password: string) => {
    setPassword(password);
    funcErrorPassword(false);
    funcErrorEmail(false);
  };

  const history = useHistory();

  const dispatch = useDispatch();

  const loginSSO = () => {
    history.push('/login-sso');
  };

  const forgotMyPassword = () => {
    history.push('/esqueci-minha-senha');
  };

  const authRequest = async (login, pass, hcaptcha) => {
    const loading = true;
    dispatch(AuthActions.authLoading(loading));
    const data = {
      email: login,
      password: pass,
      'h-captcha-response': hcaptcha,
    };
    try {
      const response = await api.user.post('/api/v1/login', data);

      const { modules, token } = response.data;
      if (modules.length == 1 && modules[0]?.user_profile == null) {
        const data = {
          uuid: modules[0].id,
        };
        try {
          const response = await api.user.post('/api/v1/login/module', data, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });

          const { companies } = response.data;
          const tokenNew = response.data.token;
          if (companies) {
            if (companies.length == 1) {
              const data = {
                uuid_company: companies[0].id,
              };
              try {
                if (
                  companies[0]?.plans == 'CANCELADO' ||
                  companies[0]?.plan == 'CANCELADO'
                ) {
                  const loading = false;
                  dispatch(AuthActions.authLoading(loading));
                  toast.error(
                    'Seu contrato de uso com a GreenPlat está vencido. Caso queira renovar acesse um dos nossos canais de atendimento.',
                    {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                } else {
                  const response = await api.user.post(
                    '/api/v1/companies/current',
                    data,
                    {
                      headers: {
                        Authorization: 'Bearer ' + tokenNew,
                      },
                    }
                  );

                  const dataNew = {
                    token: response.data.token,
                  };

                  if (modules[0].email == 'restricted') {
                    const loading = false;
                    dispatch(AuthActions.authLoading(loading));
                    dispatch(
                      AuthActions.authSuccess(
                        response.data.token,
                        modules[0],
                        companies[0]
                      )
                    );
                  } else {
                    window.location.href =
                      'api/v1/logar_by_micro_service/' + response.data.token;
                  }
                }
              } catch (error: any) {
                if (
                  error.response.data.error ==
                  'Cannot log in as the account is in use on another device!'
                ) {
                  localStorage.setItem('erro', '2');
                  toast.error(
                    ' Não é possível efetuar o login pois a conta está em uso em outro dispositivo! ',
                    {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                }
              }
            } else {
              dispatch(AuthActions.authSelectCompanie(token, { modules }));
              const loading = false;
              dispatch(AuthActions.authLoading(loading));
              history.push('/escolher-empresa-arcosdourados');
            }
          }

          // dispatch(AuthActions.authSelectCompanie(token, { companies }));
        } catch (error) {
          if (error.response.data.error == 'No companies bounded to user') {
            toast.error(
              ' Usuário inativo no sistema. Por favor entre em contato com o responsável para reativar seu acesso ao sistema! ',
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            const loading = false;
            dispatch(AuthActions.authLoading(loading));
          }
        }
      } else {
        if (
          modules[0]?.user_profile?.usuario_id != null &&
          modules.length == 1
        ) {
          window.location.href =
            terceiro.REACT_APP_TERCEIRO +
            '/logar_by_micro_service/' +
            modules[0]?.user_profile?.usuario_id +
            '/' +
            token;
        } else {
          dispatch(AuthActions.authSelectCompanie(token, { modules }));
          const loading = false;
          dispatch(AuthActions.authLoading(loading));
          history.push('/escolher-empresa-arcosdourados');
        }
      }
    } catch (error: any) {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));
      if (
        error.response.data.message ==
        'The maximum number of login attempts has been reached. We are sending you an email to reset your password.'
      ) {
        toast.error(
          'O número máximo de tentativas de login foi atingido. Estamos lhe enviando um e-mail para redefinir sua senha.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        if (
          error.response.data.message ==
          'Change your password to meet secure password requirements!'
        ) {
          toast.error(
            'Altere sua senha para atender aos requisitos de senha segura!',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          history.push(
            '/login/recriar-senha/' +
              error.response.data.data.id +
              '/' +
              error.response.data.data.token +
              '/senha-forte'
          );
        } else {
          toast.error('Login ou senha inválidos!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      funcErrorPassword(true);
      funcErrorEmail(true);
    }
  };

  const funcPassView = (passview: boolean) => {
    setShowPass(!showPass);
    setShowInput(!show_input);
  };

  return (
    <Container>
      <FormLogin>
        <LeftBox>
          <section>
            <Logo>
              <img src={logo} alt="Arcos Dourados" />
            </Logo>
            <InputArcos>
              <TextEntre>
                BEM-VINDO À SUA PLATAFORMA DE GERENCIAMENTO DE RESÍDUOS
              </TextEntre>
              <BockInputArcos error={errorEmail}>
                <InputArcosLogin
                  placeholder="Nome de usuário ou e-mail"
                  onChange={(e) => changeEmail(e.target.value)}
                />
              </BockInputArcos>
              <BockInputArcos error={errorPassword}>
                <InputArcosLogin
                  type={show_input ? 'text' : 'password'}
                  placeholder="Senha de acesso"
                  onChange={(e) => changePassword(e.target.value)}
                />
                <ImageUser onClick={() => funcPassView(showPass)}>
                  <img
                    src={showPass ? visibility : view}
                    style={{
                      position: 'absolute',
                      width: '8%',
                      float: 'left',
                      right: '8px',
                      top: '6px',
                    }}
                    alt="Plataforma Verde"
                  />
                </ImageUser>
              </BockInputArcos>
              <ReCaptcha>
                <HCaptcha
                  sitekey={recaptcha_privateke}
                  onVerify={(token) => changeRecaptha(token)}
                />
              </ReCaptcha>
              <MenuItemLink onClick={() => forgotMyPassword()}>
                <TextFooter>Esqueci meu acesso?</TextFooter>
              </MenuItemLink>
              <ButtonArcosLogin type="button" onClick={() => signInPass()}>
                ENTRAR
              </ButtonArcosLogin>
            </InputArcos>
            <ContactGreenPlateArcos />
            <SSMA>
              <img src={logoSSMA} alt="Arcos Dourados" />
            </SSMA>
          </section>
        </LeftBox>

        {/* Same as */}
        <RightBox>
          <ContentTextPage />
        </RightBox>
      </FormLogin>
      <FooterArcos>® 2022 GreenPlat™ Todos os direitos reservados</FooterArcos>
    </Container>
  );
};

export default LoginArcosDourados;
